import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Room } from '@mui/icons-material';

export const Born = ({ classes }) => {
    return (
        <Card elevation={2}>
            <CardContent className={classes.cardContent}>
                <Room />
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Geboren in
                </Typography>
                <Typography variant="body1">
                    Flensburg
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Aus
                </Typography>
                <Typography variant="body1">
                    Schleswig
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Wohnt in
                </Typography>
                <Typography variant="body1">
                    Münster
                </Typography>
            </CardContent>
        </Card>
    )
}
import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Jobs } from './biographycards/Jobs';
import { Publications } from './biographycards/Publications';
import { Education } from './biographycards/Education';
import { Born } from './biographycards/Born';

const useStyles = makeStyles(theme => ({
    cardContent: {
        minHeight: 240,
        marginBottom: theme.spacing(2)
    }
}))

export const Biography = () => {

    const classes = useStyles();

    return (
        <Grid container marginTop={1} spacing={2}>
            <Grid item sm={12} md={6} lg={4}>
                <Born classes={classes} />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
                <Education classes={classes} />
            </Grid>
            <Grid item sm={12} md={6} lg={4}>
                <Jobs classes={classes} />
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
                <Publications classes={classes} />
            </Grid>
        </Grid>
    )
}
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { School } from '@mui/icons-material';

export const Education = ({ classes }) => {

    return (
        <Card elevation={2}>
            <CardContent className={classes.cardContent}>
                <School />
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Ausbildung
                </Typography>
                <Typography variant="body1">
                    Bachelor of Science Geoinformatik
                </Typography>
                <Typography variant="body2" gutterBottom>
                    2013 - 2016 Westfälische Wilhelms-Universität Münster
                </Typography>
                <Typography variant="body1">
                    Master of Science Geoinformatics
                </Typography>
                <Typography variant="body2">
                    2016 - 2019 Westfälische Wilhelms-Universität Münster
                </Typography>
            </CardContent>
        </Card>
    )
}
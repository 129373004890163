import { AppBar, Container, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Biography } from "./components/Biography";

const useStyles = makeStyles(theme => ({
  appBar: {
    opacity: 0.8,
  }
}))

export const App = () => {

  const classes = useStyles();

  return (
    <>
      <AppBar position="sticky" className={classes.appBar} color="primary">
        <Toolbar>
          Lasse Einfeldt
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Biography/>
      </Container>
    </>
  );
}
import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {Work} from '@mui/icons-material';

export const Jobs = ({classes}) => {

    return (
        <Card elevation={2}>
            <CardContent className={classes.cardContent}>
                <Work />
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Jobs
                </Typography>
                <Typography variant="body1">
                    Studentische Hilfskraft
                </Typography>
                <Typography variant="body2" gutterBottom>
                    2016 - 2018 Westfälische Wilhelms-Universität Münster
                </Typography>
                <Typography variant="body1">
                    Studentische Hilfskraft
                </Typography>
                <Typography variant="body2" gutterBottom>
                    2018 - 2019 xdot GmbH
                </Typography>
                <Typography variant="body1">
                    Software Architekt
                </Typography>
                <Typography variant="body2">
                    2019 - heute xdot GmbH
                </Typography>
            </CardContent>
        </Card>
    )
}
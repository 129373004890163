import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { MenuBook } from '@mui/icons-material';

export const Publications = ({ classes }) => {
    return (
        <Card elevation={2}>
            <CardContent className={classes.cardContent}>
                <MenuBook />
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    Publikationen
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Einfeldt, Lasse & Degbelo, Auriol. (2021). User Interface Factors of Mobile UX: A Study with an Incident Reporting Application.
                </Typography>
                <Typography variant="body1">
                    Nüst, Daniel & Lohoff, Lukas & Einfeldt, Lasse & Gavish, Nimrod & Götza, Marlena & Jaswal, Shahzeib & Khalid, Salman & Meierkort, Laura & Mohr, Matthias & Rendel, Clara & Eek, Antonia. (2019). Guerrilla Badges for Reproducible Geospatial Data Science (AGILE 2019 Short Paper). 10.31223/osf.io/xtsqh.
                </Typography>
            </CardContent>
        </Card>
    )
}
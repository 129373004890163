import { createTheme } from "@mui/material";

const PRIMARY_MAIN = '#263238';
const PRIMARY_DARK = '#000a12';
const PRIMARY_LIGHT = '#4f5b62';
const SECONDARY_MAIN = '#ff6f00';
const SECONDARY_DARK = '#c43e00';
const SECONDARY_LIGHT = '#ffa040';

export const theme = createTheme({
    palette: {
      primary: {
          dark: {
            main: PRIMARY_DARK,
            contrastText: SECONDARY_LIGHT
          },
          light: {
            main: PRIMARY_LIGHT,
            contrastText: SECONDARY_DARK
          },
          main: PRIMARY_MAIN,
        contrastText: SECONDARY_MAIN,
      },
      secondary: {
        dark: {
            main: SECONDARY_DARK,
            contrastText: PRIMARY_LIGHT
          },
          light: {
            main: SECONDARY_LIGHT,
            contrastText: PRIMARY_DARK
          },
          main: SECONDARY_MAIN,
        contrastText: PRIMARY_MAIN,
      },
    },
  });